<template>
  <v-row justify="center" v-if="mostrar">
    <v-dialog v-model="mostrar" persistent max-width="650">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="subtitle-1" v-html="'Mover especialidad'"></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                      :items="categoriesItems"
                      v-model="categoria"
                      label="Categorías"
                      item-text="name"
                      outlined
                      dense
                      clearable
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrarForm">Cerrar</v-btn>
            <v-btn
                type="submit"
                color="blue darken-1"
                text
                @click.prevent="guardar"
            >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { espeSvr } from "@/_services/";
export default {
  name: "EspecialidadMover",
  props: ["cat", "espe"],
  data: () => ({
    mostrar: false,
    categorias: null,
    categoria: null
  }),
  computed: {
    categoriesItems() {
      if (this.categorias !== null) {
        return this.categorias.reduce((acc, curr) => {
          if(curr.cate_id!=this.cat)
            acc.push({ id: curr.cate_id, name: curr.cate_name });
          return acc;
        }, []);
      }
      return [];
    },
  },
  methods: {
    ...mapActions("especialidades", ["setSpecialtiesAll"]),
    cerrarForm() {
      this.$emit("cerrar-form");
    },
    async guardar(){
      let tmp = {
        cate_id: this.categoria.id
      };

      try {
        await espeSvr.espeUpdate(this.espe, tmp);
        this.$emit("actualizar-datos");
      } catch (error) {
        console.log(error);
      }
      this.cerrarForm();
    }
  },
  mounted() {
    this.setSpecialtiesAll().then((res) => {
      this.categorias = res.categorias;
    });
    this.mostrar = true;
  }
}
</script>

<style scoped>

</style>
