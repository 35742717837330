<template>
  <v-row justify="center" v-if="mostrar">
    <v-dialog v-model="mostrar" persistent max-width="650">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title>
            <span class="subtitle-1" v-html="compTitulo"></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Nombre de la Especialidad"
                    required
                    dense
                    v-model="localData.subc_name"
                    autocomplete="new-subc_name"
                    :rules="[v => !!v || 'Debe escribir un texto']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrarForm">Cerrar</v-btn>
            <v-btn
              type="submit"
              color="blue darken-1"
              text
              @click.prevent="guardarEspecialidad"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { espeSvr } from "@/_services/";
export default {
  name: "especialidad-form-comp",
  props: ["datos"],
  data: () => ({
    valid: true,
    mostrar: false,
    localData: null,
    editando: false
  }),
  methods: {
    /* funcion para cerrar el form */
    cerrarForm() {
      this.$emit("cerrar-form");
    },
    /* funcion para guardar una especialidad */
    async guardarEspecialidad() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      let tmp = {
        subc_name: this.localData.subc_name,
        subc_idcategory: this.datos.idCategoria
      };

      try {
        if (this.editando) {
          // si  los datos han cambiado actualizamos
          if (this.datos.datos.subc_name !== tmp.subc_name) {
            tmp = this.localData;
            await espeSvr.espeUpdate(tmp.subc_id, tmp);
            this.$emit("actualizar-datos");
          }
        } else {
          await espeSvr.espeCreate(tmp);
          this.$emit("actualizar-datos");
        }
        this.cerrarForm();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  },
  computed: {
    compTitulo() {
      return this.datos.datos
        ? "Editando especialidad: " + this.datos.datos.subc_name
        : "Crear nueva especialidad";
    }
  },
  mounted() {
    this.localData = { ...this.datos.datos };
    if (this.datos.datos) {
      this.editando = true;
    }
    this.mostrar = true;
  }
};
</script>

<style scoped></style>
