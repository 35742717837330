<template>
  <v-row dense>
    <v-col cols="12">
      <v-expansion-panels hover style="min-width:545px">
        <v-expansion-panel>
          <v-expansion-panel-header color="orange" class="white--text">
            <div v-html="compTitulo"></div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table dense width="370">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Categoría</th>
                    <th class="text-left">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cat in cats" :key="cat.cate_id">
                    <td>{{ cat.cate_name }}</td>
                    <td width="150">
                      <v-tooltip left color="success">
                        <template v-slot:activator="{ on }">
                          <v-btn small icon v-on="on" @click="nuevaEsp(cat)">
                            <v-icon small color="blue">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Añadir Especialidad</span>
                      </v-tooltip>
                      <v-tooltip left color="blue">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            color="indigo"
                            icon
                            v-on="on"
                            @click="editar(cat)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar Categoría</span>
                      </v-tooltip>
                      <v-tooltip left color="red">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            icon
                            color="red"
                            v-on="on"
                            @click="eliminar(cat)"
                          >
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar Categoría</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "espe-sin-cat-comp",
  props: ["cats"],
  data: () => ({}),
  methods: {
    eliminar(cat) {
      this.$emit("eliminar-cat", cat.cate_id);
    },
    nuevaEsp(cat) {
      const idCategoria = cat.cate_id;
      this.$emit("crear-esp", idCategoria);
    },
    editar(cat) {
      this.$emit("editar-categoria", cat.cate_id);
    }
  },
  computed: {
    compTitulo() {
      return `<b>Atención</b>: Categorías sin especialidad&nbsp;<b>${this.cats.length}</b>`;
    }
  }
};
</script>

<style scoped></style>
