<template>
  <v-container class="fill-height" fluid v-if="!loading">
    <v-row v-resize="onResize" dense>
      <v-col cols="12" md="6">
        <v-text-field
          ref="refBuscar"
          outlined
          clearable
          dense
          value=""
          name="filtro"
          label="Filtrar por Categoría / Especialidad"
          autocomplete="off"
          v-model="filtro"
        ></v-text-field>
      </v-col>
      <!-- selector para filtrar -->
      <v-col cols="12" md="2">
        <v-select
          outlined
          dense
          :items="limiteItems"
          v-model="mostrarItems"
          label="Mostrar"
        ></v-select>
      </v-col>
      <!-- boton para mostrar el form -->
      <v-col cols="12" md="4">
        <v-tooltip left color="success">
          <template v-slot:activator="{ on }">
            <v-btn
              outlined
              color="primary"
              class="float-right mr-1"
              v-on="on"
              @click="crearCategoria"
            >
              <v-icon left>mdi-plus-box</v-icon>
              Nueva Categoría
            </v-btn>
          </template>
          <span>Nueva Categoría</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- tabla de categorías sin especialidades -->
    <v-row v-if="!!temp && misCatSinSub.length > 0">
      <v-col cols="12" md="6">
        <cat-sin-esp
          :cats="misCatSinSub"
          @editar-categoria="editarCategoria"
          @crear-esp="crearEspecialidad"
          @eliminar-cat="eliminarCategoria"
        ></cat-sin-esp>
      </v-col>
    </v-row>

    <v-row v-if="!!temp">
      <div
        v-masonry
        transition-duration="0.3s"
        item-selector=".item"
        :origin-top="true"
        :horizontal-order="false"
      >
        <v-row class="mx-auto pb-10">
          <div v-masonry-tile v-for="(dato, n) in temp" :key="n">
            <v-card outlined class="elevation-2 ma-2" min-width="550">
              <v-card-title class="title">
                <v-container fluid class="pa-0 ma-0 categorias">
                  {{ n | fCategoria(categorias) }}
                  <v-chip
                    class="mt-0 pt-0"
                    label
                    small
                    color="primary"
                    text-color="white"
                    >{{ dato.length }}</v-chip
                  >
                  <div class="btn-cats oculto">
                    <!-- eliminar categoria -->
                    <v-tooltip top color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          color="red"
                          v-on="on"
                          class="float-right"
                          @click="
                            mostrarDialogo(
                              'eliminarCat',
                              dato[0].subc_idcategory
                            )
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                    <!-- añadir especialidad a una categoria -->
                    <v-tooltip bottom color="blue">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          color="blue"
                          v-on="on"
                          class="float-right"
                          @click="crearEspecialidad(dato[0].subc_idcategory)"
                        >
                          <v-icon>mdi-bookmark-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Nueva Especialidad</span>
                    </v-tooltip>
                    <!-- editar especialidad -->
                    <v-tooltip left color="success">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          icon
                          color="blue"
                          v-on="on"
                          class="float-right"
                          @click="editarCategoria(dato[0].subc_idcategory)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </div>
                </v-container>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row v-for="d in dato" :key="d.subc_id" dense>
                  <div style="width: 100%" class="especialidad">
                    {{ d.subc_name }} {{ d.subc_description }}
                    <div class="float-right oculto">
                      <v-btn
                        x-small
                        icon
                        color="red"
                        class="float-right"
                        @click="mostrarDialogo('eliminarEsp', d.subc_id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                        x-small
                        icon
                        color="primary"
                        class="float-right"
                        @click="editarEspecialidad(d)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                          x-small
                          icon
                          color="primary"
                          class="float-right"
                          @click="moverEspecialidad(d)"
                      >
                        <v-icon>mdi-redo-variant</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </div>
    </v-row>
    <!-- mensaje de alerta si no se encuentran resultados -->
    <v-row>
      <v-col cols="12">
        <v-alert
          class="text-center"
          type="info"
          v-if="Object.keys(temp).length === 0"
        >
          No se han encontrado datos con el filtro actual
        </v-alert>
      </v-col>
    </v-row>
    <!-- componente form especialidad - modal -->
    <especialidad-form
      v-if="especialidad.mostrar"
      :datos="especialidad"
      @cerrar-form="cerrarForms"
      @actualizar-datos="actualizarDatos"
    ></especialidad-form>
    <!-- componente form categoria - modal  -->
    <categoria-form
      v-if="categoria.mostrar"
      :datos="categoria"
      @cerrar-form="cerrarForms"
      @actualizar-datos="actualizarDatos"
    ></categoria-form>

    <especialidad-mover
        v-if="mostrarEspecialidadMover"
        :cat="categoriaMover"
        :espe="especialidadMover"
        @cerrar-form="cerrarEspecialidadMover"
        @actualizar-datos="actualizarDatos"
    ></especialidad-mover>

    <!-- component dialogo preguntar - modal -->
    <dialog-all
      :mostrar="dialog.mostrar"
      @emitAceptar="dialogAceptar"
      @emitCancelar="mostrarDialogo('')"
      :cabecera="dialog.cabecera"
      :cuerpo="dialog.cuerpo"
      :maxWidth="450"
    ></dialog-all>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
// import VueMasonry from "vue-masonry-css";
import { VueMasonryPlugin } from "vue-masonry";
import _ from "lodash";
import { cateSvr, espeSvr } from "@/_services/";

Vue.use(VueMasonryPlugin);

import CategoriaForm from "./parts/CategoriaForm";
import EspecialidadForm from "./parts/EspecialidadForm";
import CatSinEsp from "./parts/CatSinEsp";
import DialogAll from "@/components/comun/Dialog";
import EspecialidadMover from "./parts/EspecialidadMover";

export default {
  name: "especialidades",
  metaInfo: {
    title: 'Especialidades'
  },
  components: {
    EspecialidadForm, // subcategorias -> dentro de la especialidad
    CategoriaForm,
    CatSinEsp,
    DialogAll,
    EspecialidadMover
  },
  data: () => ({
    otroFiltro: null,
    mostrarForm: false,
    filtro: null,
    loading: true,
    categorias: null,
    subcategorias: null,
    selectedCat: null,
    filtroCat: null,
    misCategorias: null,
    misCatAgrupadas: null,
    misCatSinSub: null,
    temp: null,
    tmpFiltro: null,
    limiteItems: [10, 15, 25, 50, 100, 200],
    mostrarItems: 25,
    windowSize: {
      x: 0,
      y: 0,
    },
    especialidad: {
      idCategoria: null,
      mostrar: false,
      editar: false,
      datos: null,
    },
    categoria: {
      mostrar: false,
      editar: false,
      datos: null,
    },
    dialog: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
      tipo: null,
    },
    mostrarEspecialidadMover: false,
    categoriaMover: null,
    especialidadMover: null
  }),

  methods: {
    ...mapActions("especialidades", ["setSpecialtiesAll"]),
    ...mapActions("comun", ["setSnackBar"]),
    /* funcion para actualizar datos - normalmente desde el child se le llama */
    actualizarDatos() {
      this.cerrarForms();
      this.getTodasEspecialidades();
    },
    /* masonry */
    handleScroll() {
      let scrollHeight = window.scrollY;
      let maxHeight =
        window.document.body.scrollHeight -
        window.document.documentElement.clientHeight;

      if (scrollHeight >= maxHeight - 200) {
        this.getPosts();
      }
    },
    /* masonry */
    /* cerrar form especialidad/categoria */
    cerrarForms() {
      setTimeout(() => {
        this.especialidad = {
          idCategoria: null,
          mostrar: false,
          editar: false,
          datos: null,
        };
        this.categoria = {
          mostrar: false,
          editar: false,
          datos: null,
        };
      }, 150);
    },

    cerrarEspecialidadMover(){
      this.mostrarEspecialidadMover=false;
    },

    crearEspecialidad(idCategoria) {
      // llamamos al componente form crear/editr subcategorias
      this.especialidad.idCategoria = idCategoria;
      this.especialidad.mostrar = true;
    },

    editarEspecialidad(datos) {
      this.especialidad.datos = datos;
      this.especialidad.mostrar = true;
    },

    mostrarDialogo(tipo, id = null) {
      switch (tipo) {
        case "eliminarCat":
          this.dialog.cabecera = "Aviso:";
          this.dialog.cuerpo = "¿Quiere realmente eliminar la categoría?";
          this.dialog.id = id;
          this.dialog.tipo = "cat";
          this.dialog.mostrar = true;
          break;

        case "eliminarEsp":
          this.dialog.cabecera = "Aviso:";
          this.dialog.cuerpo = "¿Quiere realmente eliminar la especialidad?";
          this.dialog.id = id;
          this.dialog.tipo = "esp";
          this.dialog.mostrar = true;
          break;

        default:
          this.dialog = {
            mostrar: false,
            cabecera: null,
            cuerpo: null,
            id: null,
            tipo: null,
          };
          break;
      }
    },

    /* dialogo de aceptación para eliminar */
    dialogAceptar() {
      switch (this.dialog.tipo) {
        case "esp":
          this.eliminarEspecialidad(this.dialog.id);
          break;
        case "cat":
          this.eliminarCategoria(this.dialog.id);
          break;

        default:
          break;
      }

      this.mostrarDialogo("");
    },

    async eliminarEspecialidad(id) {
      try {
        if (id) {
          const response=await espeSvr.espeDelete(id);
          if(response.data===false){
            const miSnack = {
              mostrar: true,
              texto: response.message,
              color: "error",
            };
            this.setSnackBar(miSnack);
          }
          else
            this.actualizarDatos();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },

    crearCategoria() {
      // llamamos al componente form crear/editr categorias
      this.categoria.mostrar = true;
    },

    moverEspecialidad(especialidad) {
      this.categoriaMover=especialidad.subc_idcategory;
      this.especialidadMover=especialidad.subc_id;
      this.mostrarEspecialidadMover = true;
    },

    editarCategoria(id) {
      const datos = this.categorias.find((c) => c.cate_id === id);
      this.categoria = {
        mostrar: true,
        editar: true,
        datos,
      };
    },

    async eliminarCategoria(id) {
      try {
        if (id) {
          const response=await cateSvr.cateDelete(id);
          if(response.data===false){
            const miSnack = {
              mostrar: true,
              texto: response.message,
              color: "error",
            };
            this.setSnackBar(miSnack);
          }
          else
            this.actualizarDatos();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },

    getTodasEspecialidades() {
      this.loading = true;
      this.$store.commit("comun/SET_MAIN_LOADER", true);

      this.setSpecialtiesAll().then((res) => {
        this.categorias = res.categorias;
        this.subcategorias = [...res.subcategorias];
        this.misCatSinSub = [...res.espSinSub];
        this.generarLista([...res.subcategorias]);
      });
    },

    generarLista(subcategorias) {
      this.misCategorias = _.cloneDeep(subcategorias);
      this.misCategorias.forEach((c) => {
        c.cat = tratarTexto(c.cat_nombre);
        c.subc = tratarTexto(c.subc_name);
      });

      //guardamos las categorias agrupadas en un objeto parar recuperarlo
      this.misCatAgrupadas = _.groupBy(this.misCategorias, "subc_idcategory");

      // this.temp = _.cloneDeep(this.misCatAgrupadas);
      const tmp = _.cloneDeep(this.misCatAgrupadas);

      this.temp = Object.fromEntries(
        Object.entries(tmp).slice(0, this.mostrarItems)
      );
      this.loading = false;
      this.$store.commit("comun/SET_MAIN_LOADER", false);
    },

    filtrarDatos() {
      const tmp = filtrar(
        this.tmpFiltro,
        this.misCategorias,
        this.misCatAgrupadas,
        this.mostrarItems
      );
      this.temp = tmp;
      setTimeout(() => {
        this.$redrawVueMasonry();
      }, 250);
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },

  computed: {
    compColsDefault() {
      if (Object.keys(this.temp).length < 3 && this.windowSize.x >= 1100) {
        return { default: 2 };
      }
      return this.colsDefault;
    },
  },
  watch: {
    /* sin uso actualmente */
    filtro: _.debounce(function (newValue) {
      // console.log("filtro deounced", newValue);
      this.tmpFiltro = newValue;
      this.filtrarDatos();
    }, 400),
    /* ejecutamos el filtro en tiempo real */
    // eslint-disable-next-line no-unused-vars
    filtro_: function (newValue, oldValue) {
      this.tmpFiltro = newValue;
      this.filtrarDatos();
    },
    /* al cambiar el selecto de límite refiltramos */
    mostrarItems: function (newValue, oldValue) {
      if (newValue === oldValue) return;
      this.filtrarDatos();
    },
  },
  filters: {
    fCategoria: function (value, categorias) {
      return categorias.find((c) => c.cate_id === parseInt(value)).cate_name;
    },
  },

  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    //  this.getPosts();
    this.getTodasEspecialidades();
    this.onResize();
  },
};

const filtrar = (tmpFiltro, misCategorias, catAgrupadas, limite = 25) => {
  let tmp = null;

  if (tmpFiltro) {
    tmpFiltro = tratarTexto(tmpFiltro);
    tmp = misCategorias.filter((c) => {
      if (
        compararRegexStr(tmpFiltro, c.cat) ||
        compararRegexStr(tmpFiltro, c.subc)
      ) {
        return true;
      }
    });
    const salida = _.groupBy(tmp, "subc_idcategory");
    const s2 = Object.fromEntries(Object.entries(salida).slice(0, limite));
    //console.log(Object.keys(salida).length, s2)

    return s2;
  } else {
    //en principio esta opción no entra nunca
    const s2 = Object.fromEntries(
      Object.entries(catAgrupadas).slice(0, limite)
    );
    return s2;
  }
};
const tratarTexto = (texto) => {
  if (!texto) {
    return "";
  }
  const sustituir = { á: "a", é: "e", í: "i", ó: "o", ú: "u" };

  return texto.toLowerCase().replace(/[^\w]/gi, function (str) {
    return sustituir[str] || str;
  });
};

const compararRegexStr = (filtro, str) => {
  let pattern = str.toLowerCase();
  /*  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u"); */

  let _value_ = filtro.toLowerCase();
  // _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  _value_ = new RegExp(_value_, "gi");
  return _value_.test(pattern);
};
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
.especialidad:hover {
  background-color: rgb(198, 197, 248);
  padding: 5px;
  font-weight: bold;
}
.especialidad > div.oculto {
  display: none;
}
.especialidad:hover > div.oculto {
  display: contents;
}
.btn-cats {
  width: 120px;
  float: right;
}
.categorias:hover {
  color: red;
}
.categorias > div.oculto {
  display: none;
}
.categorias:hover > div.oculto {
  display: contents;
}
</style>
